<template>
  <div class="programa_two">
    <el-row type="flex" justify="center" class="mainWidth">
      <el-col :span="23">
        <div class="other_btn_phone" v-if="$store.state.isEquipment == 'phone'">
          <router-link target="_blank" to="/zhuantiList">
            <el-button type="text" class="other_btn">
              更多
              <i class="el-icon-arrow-right"></i>
            </el-button>
          </router-link>
        </div>
        <div class="container_pc">
          <Swiper :options="swiperOption" v-if="swiperList.length">
            <SwiperSlide v-for="(item, index) in swiperList" :key="index">
              <div
                align="center"
                :class="[
                  swiperList[index].list.length == '2' ? 'two' : 'three',
                ]"
              >
                <div
                  class="crad"
                  v-for="(val, index) in swiperList[index].list"
                  :key="index"
                >
                  <a :href="val.XINWENZIXUN_LINK_ADDRESS" target="_blank">
                    <img :src="val.pub_cover" />
                    <div class="crad_content">
                      <p>{{ val.SYS_TOPIC }}</p>
                    </div>
                  </a>
                </div>
              </div>
            </SwiperSlide>
            <div class="swiper-pagination" slot="pagination"></div>
          </Swiper>
          <div class="arrows">
            <img
              src="@/assets/image/home/图层 16.png"
              alt=""
              class="pc-button-prev"
            />
            <img
              src="@/assets/image/home/图层 16.png"
              alt=""
              class="pc-button-next"
              style="transform: rotateZ(180deg)"
            />
          </div>
        </div>

        <!-- <div class="container_phone" v-else>
          <Swiper :options="swiperOptionPhone" v-if="list.length">
            <SwiperSlide v-for="(item, index) in list" :key="index">
              <router-link target="_blank" to="/">
                <div class="crad">
                  <img v-lazy="item.pub_cover" alt="" />
                  <div class="crad_content">
                    <p>{{ item.text }}</p>
                  </div>
                </div>
              </router-link>
            </SwiperSlide>
            <div class="swiper-pagination" slot="pagination"></div>
          </Swiper>
        </div> -->
      </el-col>
      <el-col :span="1" v-if="$store.state.isEquipment !== 'phone'">
        <router-link target="_blank" to="/zhuantiList">
          <el-button type="text" class="other_btn">
            更多
            <i class="el-icon-arrow-right"></i>
          </el-button>
        </router-link>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import api from "@/api/index.js";
export default {
  name: "",

  components: {
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      ceshi: "",
      swiperList: [],
      swiperOption: {
        initialSlide: 0,
        // loop: true,
        paginationClickable: true,
        EffectCards: true,
        slidesPerView: this.$store.state.isEquipment == "phone" ? 1 : 3,
        spaceBetween: 14,
        navigation: {
          nextEl: ".pc-button-next",
          prevEl: ".pc-button-prev",
        },
      },
      swiperOptionPhone: {
        initialSlide: 0,
        paginationClickable: true,
        loop: true,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        autoplay: {
          disableOnInteraction: false, // 用户操作swiper之后，是否禁止autoplay
          delay: 3000, // 自动切换的时间间隔（单位ms）
        },
      },
      list: [],
    };
  },

  created() {},

  methods: {},
  mounted() {
    const resultArray = [];
    api
      .post("spc/cms/publish/queryList.do", {
        s: 2,
        c: 85,
      })
      .then((res) => {
        this.list = res.result;
        console.log(res.result);
        // this.ceshi = res.result.slice(0, 15); //轮播图选取数据中的多少项
        // 把数据每5项中前3项分为一组后两项分为一组
        let i = 0;
        while (i < res.result.length) {
          resultArray.push({
            list: res.result.slice(i, (i += i % 5 === 0 ? 3 : 2)),
          });
        }
        this.swiperList = resultArray;
      });
  },
};
</script>

<style lang='scss' scoped>
.programa_two {
  padding: 2.5rem 0rem;
  position: relative;

  .other_btn_phone {
    text-align: right;
  }

  .container_pc {
    height: 742px;
  }

  .container_pc,
  .container_phone {
    .crad {
      position: relative;
      margin-bottom: 1.25rem;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        display: block;
      }

      .crad_content {
        // width: 100%;
        padding: 1rem;
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        width: 100%;
        box-sizing: border-box;
        bottom: 0%;

        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 1.125rem;
          line-height: 1.375rem;
          color: #ffffff;
        }
      }
    }

    .swiper-container {
      padding-bottom: 3rem;
    }

    .arrows {
      position: absolute;
      bottom: 0px;
      left: 50%;
      z-index: 999;
      transform: translateX(-50%);
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      cursor: pointer;

      img {
        width: 2.5rem;
        height: 2.5rem;
        margin: 0 0.3125rem;
      }
      .swiper-button-disabled {
        opacity: 0.35;
      }
    }
  }

  .container_phone {
    .crad {
      margin-bottom: 0;
      img {
        height: auto;
        aspect-ratio: 4/3;
      }
    }
  }

  .container_pc {
    .two {
      .crad {
        // width: 28.125rem;
        height: 20.35rem;
      }
    }

    .three {
      .crad {
        // width: 28.125rem;
        height: 13.125rem;
      }
    }
  }

  .container_phone {
    .two {
      .crad {
        width: auto;
        height: 20.4375rem;
      }
    }

    .three {
      .crad {
        width: auto;
        height: 13.125rem;
      }
    }

    .crad .crad_content {
      padding: 0rem 3.4375rem;
      line-height: 3rem;
    }
  }

  .other_btn {
    // position: absolute;
    // top: 7%;
    // left:83rem;
    margin-left: 1.25rem;
    font-size: 1.25rem;
  }
}

@media screen and(max-width:1440px) {
  .programa_two .container_pc {
    height: 649.5px;
  }
}


.phone .programa_two {
  padding: 0;
  .container_phone {
    height: auto;
    .swiper-container {
      padding-bottom: 0;
    }
  }
  .container_pc {
    height: auto;
  }
}
// .phone .programa_two .container_pc .three .crad{
//   height: auto;
// }
</style>