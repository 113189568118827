<template>
  <div class="programa_one">
    <div class="mainWidth">
      <div class="container_pc" v-if="$store.state.isEquipment !== 'phone'">
        <el-row>
          <el-col :span="16">
            <div
              class="detail"
              v-for="(item, index) in shipwai"
              :key="index"
              v-show="itemIndex == index"
            >
              <a :href="item.XINWENZIXUN_LINK_ADDRESS">
                <img
                  :src="item.pub_picBig"
                  v-if="index == 0 || index == shipwai.length - 1"
                />
                <img v-lazy="item.pub_picBig" alt="" v-else />
                <div class="bottom_detail">
                  <p>{{ item.SYS_TOPIC }}</p>
                </div>
              </a>
            </div>
          </el-col>
          <el-col :span="8" class="nav_btn_wrap">
            <div
              class="nav_btn"
              v-for="(item, index) in shipwai"
              :key="index"
              @mouseenter="btnClick(index)"
              :class="{ active: itemIndex == index }"
            >
              <p>{{ item.SYS_TOPIC }}</p>
            </div>
          </el-col>
        </el-row>
      </div>

      <div class="container_phone" v-else>
        <Swiper :options="swiperOption">
          <SwiperSlide v-for="(item, index) in shipwai" :key="item.index">
            <div class="crad">
              <a :href="item.XINWENZIXUN_LINK_ADDRESS">
                <img
                  :src="item.pub_picBig"
                  v-if="index == 0 || index == shipwai.length - 1"
                />
                <img v-lazy="item.pub_picBig" alt="" v-else />
                <p class="title">{{ item.SYS_TOPIC }}</p>
              </a>
            </div>
          </SwiperSlide>
          <div class="swiper-pagination" slot="pagination"></div>
        </Swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import api from "@/api/index.js";
export default {
  name: "",

  components: {
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      itemIndex: 0,
      shipwai: "",
      tabqie: "",
      swiperOption: {
        // autoplay: {
        //   disableOnInteraction: false, // 用户操作swiper之后，是否禁止autoplay
        //   delay: 3000, // 自动切换的时间间隔（单位ms）
        // },
        initialSlide: 0,
        loop: true,
        pagination: { el: ".swiper-pagination" }, // 分页按钮
        paginationClickable: true,
        EffectCards: true,
      },
    };
  },

  created() {},

  methods: {
    btnClick(index) {
      this.itemIndex = index;
    },
  },
  mounted() {
    api
      .post("spc/cms/publish/queryList.do", {
        s: 2,
        c: 84,
      })
      .then((res) => {
        console.log(res);
        this.shipwai = res.result.slice(0, 5);
      });
  },
};
</script>

<style scoped lang="scss">
.programa_one {
  background: url("../../assets/image/zhuanti/banner.png");
  background-size: 100% 100%;
  padding: 4.43rem 0rem 4rem 0rem;

  .container_pc {
    cursor: pointer;
    height: 460px;
    .detail {
      height: 28.75rem;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        display: block;
      }

      .bottom_detail {
        padding: 1rem;
        background: rgba(31, 44, 84, 0.7);
        // opacity: 0.7;
        width: 100%;
        box-sizing: border-box;
        position: absolute;
        bottom: 0rem;
        // margin-top: -5.75rem;

        p {
          font-size: 1.25rem;
          font-weight: bold;
          color: #ffffff;
          line-height: 2rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .nav_btn_wrap {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
    }
    .nav_btn {
      flex-grow: 1;
      display: flex;
      align-items: center;
      background: linear-gradient(
        90deg,
        rgba(32, 46, 90, 0.9),
        rgba(26, 31, 51, 0.9)
      );
      // opacity: 0.9;
      padding-left: 2.0625rem;
      color: #ffffff;
      font-size: 1.25rem;
      font-weight: bold;
      color: #ffffff;
      border-bottom: 1px solid #000000;
      p {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }
    }

    .active {
      background: linear-gradient(90deg, #510e0e, #ad241e);
    }
  }

  .container_phone {
    // margin:0 2%;
    .crad {
      position: relative;

      img {
        width: 100%;
        height: 12.5rem;
        display: block;
      }

      .title {
        position: absolute;
        bottom: 0%;
        height: 2rem;
        line-height: 2rem;
        background: rgba(31, 44, 84, 0.8);
        color: #ffffff;
        width: 100%;
        padding: 0.3rem 1rem;
      }
    }

    .swiper-container {
      padding-bottom: 2.8125rem;
    }

    .swiper-pagination {
      // text-align: right;
      padding-right: 0.4rem;
      padding-bottom: 0.2rem;
    }

    .swiper-pagination-bullet {
      background: #ffffff;
    }

    .swiper-pagination-bullet-active {
      background: #eeeeee !important;
    }
  }
}
@media screen and(max-width:1440px) {
  .programa_one .container_pc {
    height: 402.5px;
  }
}
.phone .programa_one {
  min-height: 300px;
  .container_phone .crad img {
    height: auto;
    aspect-ratio: 4/3;
  }
}
</style>
<style lang="scss">
.container_phone {
  .swiper-container-horizontal
    > .swiper-pagination-bullets
    .swiper-pagination-bullet {
    background: #ffffff !important;
  }

  .swiper-pagination-bullet-active {
    background: #eeeeee !important;
  }
}
</style>