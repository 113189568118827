<template>
  <div class="zhuanti">
    <ProgramaOne />
    <ProgramaTwo />
  </div>
</template>

<script>

import ProgramaOne from "@/pages/zhuanti/Programa_one.vue"
import ProgramaTwo from "@/pages/zhuanti/Programa_two.vue"
export default {
  name: 'ZhuanTi',
  props: {
    msg: String
  },
  components: {
    // Navigation,
    ProgramaOne,
    ProgramaTwo,
  }
}
</script>
<style scoped lang="scss">
h3 {
  margin: 2.5rem 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 .625rem;
}

a {
  color: #42b983;
}

.zhuanti {
  background: url("../../assets/image/home/pic(7).png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: 0% 100%;
}
</style>
